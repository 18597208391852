import React from 'react';
import './App.css';

import { Switch, Route } from 'react-router-dom';
import HomePage from './pages/homepage/homepage.componenet';
import About from './pages/about/about.component';

const App = () => (
  <div>
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/about/' component={About} />
    </Switch>
  </div>
)

export default App;
