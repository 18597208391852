import React from 'react';
import './homepage.style.scss';

import { Link } from 'react-router-dom';

import sergiuImage from '../../assets/images/sergiu.jpg';

const HomePage = () => (
    <div className="homepage">
        <div className="homepage-image">
            <img src={sergiuImage} alt="sergiu" />
        </div>
        <div className="homepage-text">
            <h2>Web Developer</h2>
            <h1>Sergiu <span className="bold">Avram</span></h1>
            {/* <Link to='/about/' className='link'>About Me</Link> */}
            <Link to='/about/' className='link'>Portofolio</Link>
        </div>
    </div>
)

export default HomePage;