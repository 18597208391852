import React from 'react';
import './project-details.style.scss';

const ProjectDetails = ({ project }) => {

    return (
        <div className="project-details">
            <p>Url: <a href={`${project.url}`}>{`${project.url}`}</a></p>
            {
                project.sourceCode === undefined
                    ?
                    ''
                    :
                    <p>Source Code: <a href={`${project.sourceCode}`}>{`${project.sourceCode}`}</a></p>

            }
            {
                project.sourceCode2 === undefined
                    ?
                    ''
                    :
                    <p className='sourcecode2'><a href={`${project.sourceCode2}`}>{`${project.sourceCode2}`}</a></p>
            }
            {
                project.status === undefined
                    ?
                    ''
                    :
                    <p>Status: {project.status}</p>
            }

        </div>
    );
};

export default ProjectDetails;
