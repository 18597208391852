import React from 'react';
import './custom-button.style.scss';

const CustomButton = ({ project, projectId, changeId }) => {

    return (
        <div
            // style={{ backgroundColor: project.id === projectId ? 'red' : null }}
            className={`custom-button ${project.id === projectId ? 'active' : null}`} onClick={() => changeId(project.id)}
        >
            {project.name}
        </div>
    );
};

export default CustomButton;
