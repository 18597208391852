import React, { useState } from 'react';
import './portofolio.style.scss';

import Iframe from '../iframe/iframe.component';
import CustomButton from '../custom-button/custom-button.component';
import ProjectDetail from '../project-details/project-details.component';

const Portofoion = () => {
    const [projectId, setPorjectId] = useState(1);

    const projects = [
        {
            id: 0,
            name: 'Monsters Rolodex',
            url: 'https://digitalcodebender.github.io/monsters/',
            sourceCode: 'https://github.com/digitalcodebender/monsters'
        },
        {
            id: 1,
            name: 'Meme Lord',
            url: 'https://dwy-meme-lord.herokuapp.com',
            sourceCode: 'https://github.com/digitalcodebender/meme-lord'
        },
        {
            id: 2,
            name: 'Beehive',
            url: 'https://dcb-beehive-react.herokuapp.com/',
            sourceCode: 'https://github.com/sergiuavram/beehive-react',
            sourceCode2: 'https://github.com/sergiuavram/beehive-node',
            status: 'under construction...'
        }
    ]

    return (
        <div className="portofolio">
            <h2>My Projects</h2>
            <div className="projects">
                {projects.map(project => <CustomButton changeId={setPorjectId} project={project} projectId={projectId} key={project.id} />)}
            </div>
            <ProjectDetail project={projects[projectId]} />
            <Iframe project={projects[projectId]} />
        </div>
    );
};

export default Portofoion;

