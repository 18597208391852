import React from 'react';
import './iframe.style.scss';

const Iframe = ({ project }) => {

    return (
        <div className="iframe">
            <div className="iframe-content">
                <iframe
                    title='project'
                    name='project'
                    src={project.url}
                    frameBorder="0"
                    width='100%'
                    height='560'
                >

                </iframe>
            </div>
        </div>
    );
};

export default Iframe;
