import React from 'react';
import './designer-coder.style.scss';

import coderDesigner from '../../assets/images/coder designer500.png';

const DesignerCoder = () => (
    <div className="designer-coder">
        <div className="coder designer-coder-section">
            {/* <h2>Part Coder</h2> */}
            <p><strong>FRONT END DEVELOPER</strong></p>
            <p>HTML / CSS</p>
            <p>JavaScript / ReactJS / React Native</p>
            <p>Redux</p>
            <p><strong>BACK-END DEVELOPER</strong></p>
            <p>NodeJS</p>
            {/* <p>Loving my Laptop</p> */}
        </div>
        <div className="img designer-coder-section">
            <img src={`${coderDesigner}`} alt="" />
        </div>
        <div className="designer designer-coder-section">
            {/* <h2>Part Designer</h2> */}
            <p>UI Design</p>
            <p>UX Design</p>
            <p>Inspiration from others</p>
        </div>
    </div>
)

export default DesignerCoder;