import React, { useState } from 'react';
import './progress-bar.style.scss';

const ProgressBar = ({ time, done, color, skill }) => {
    const [style, setStyle] = useState({});

    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: `${done}%`,
            background: color,
        }

        setStyle(newStyle);
    }, time);

    return (
        <div className="progress-bar">
            <div className="progress">
                <div
                    className="progress-done"
                    style={style}
                >
                    {`${skill} ${done}%`}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
