import React from 'react';
import './skills.style.scss';

//images
// import html from '../../assets/images/html-logo.png';
// import css from '../../assets/images/css-logo.png';
// import js from '../../assets/images/js-logo.png';
// import react from '../../assets/images/react-logo.png';
// import reactNative from '../../assets/images/react-native-logo.png';
// import node from '../../assets/images/node-logo.png';
// import redux from '../../assets/images/redux-logo.png';

import ProgressBar from '../progress-bar/progress-bar.component';
// import ProgressBar2 from '../progress-bar2/progress-bar2.components';

const Skills = () => (
    <div className="skills">
        <h2 className='skills-title'>My Skills</h2>

        <div className="progress-line">
            <ProgressBar time={300} done="80" color='#f06529' skill='html' />
            <ProgressBar time={400} done="65" color='#3483eb' skill='css' />
            <ProgressBar time={500} done="85" color='#e3b71b' skill='js' />
            <ProgressBar time={600} done="70" color='#50c2df' skill='react' />
            <ProgressBar time={700} done="30" color='#50c2df' skill='react-native' />
            <ProgressBar time={800} done="70" color='#744cb8' skill='redux' />
            <ProgressBar time={900} done="73" color='#3C8f3A' skill='node' />
        </div>

        {/* <div className="logo">
            <ProgressBar2 maxValue={72} skill={'html'} color={'#f06529'} image={html} />
            <ProgressBar2 maxValue={72} skill={'css'} color={'#4C9AFF'} image={css} />
            <ProgressBar2 maxValue={72} skill={'js'} color={'#FFD43B'} image={js} />
            <ProgressBar2 maxValue={72} skill={'react'} color={'#61DBFB'} image={react} />
            <ProgressBar2 maxValue={72} skill={'react-native'} color={'#61DBFB'} image={reactNative} />
            <ProgressBar2 maxValue={72} skill={'redux'} color={'#744cb8'} image={redux} />
            <ProgressBar2 maxValue={72} skill={'node'} color={'#3C8f3A'} image={node} />
        </div> */}

    </div>
)

export default Skills;

