import React from 'react';
import './about.style.scss';

import DesignerCoder from '../../components/designer-coder/designer-coder.component';
import Skills from '../../components/skills/skills.component';
import Portofoion from '../../components/portofolio/portofolio.component';

const About = () => (
    <div className="about">
        <DesignerCoder />
        <Skills />
        <Portofoion />
    </div>
)

export default About;


